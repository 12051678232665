import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  container: {
    height: "100vh",
    width: "100vw",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    //backgroundColor: theme.palette.primary.main,
    position: "absolute",
    top: 0,
    left: 0,
  },
  logotype: {
    display: "flex",
    alignItems: "center",
    marginBottom: "14px",
    // [theme.breakpoints.down("sm")]: {
    //   display: "none",
    // },
  },
  logotypeText: {
    fontWeight: 500,
    marginLeft: "4px",
  },
  logotypeIcon: {
    width: 70,
    marginRight: "4px",
  },
  paperRoot: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: "12px",
    paddingBottom: "12px",
    paddingLeft: "10px",
    paddingRight: "10px",
    maxWidth: 404,
  },
  textRow: {
    marginBottom: "8px",
    textAlign: "center",
  },
  errorCode: {
    fontSize: 148,
    fontWeight: 600,
  },
  safetyText: {
    fontWeight: 300,
    //color: theme.palette.text.hint,
  },
  backButton: {
    textTransform: "none",
    fontSize: 22,
  },
}));
