import React from "react";
import axios from "axios";
import { config } from "../config";

var UserStateContext = React.createContext();
var UserDispatchContext = React.createContext();

function userReducer(state, action) {
  switch (action.type) {
    case "LOGIN_SUCCESS":
      return { ...state, isAuthenticated: true };
    // case "LOGIN_FAILURE":
    //   return { ...state, isAuthenticated: false };
    case "SIGN_OUT_SUCCESS":
      return { ...state, isAuthenticated: false };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function UserProvider({ children }) {
  var [state, dispatch] = React.useReducer(userReducer, {
    isAuthenticated: !!localStorage.getItem("id_token"),
  });

  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>
        {children}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
}

function useUserState() {
  var context = React.useContext(UserStateContext);
  if (context === undefined) {
    throw new Error("useUserState must be used within a UserProvider");
  }
  return context;
}

function useUserDispatch() {
  var context = React.useContext(UserDispatchContext);
  if (context === undefined) {
    throw new Error("useUserDispatch must be used within a UserProvider");
  }
  return context;
}

// ###########################################################
function authenticateEnterpriseUser(dispatch, token, org, user, props) {
  let userName = user;
  let orgName = org;
  let oldToken = token;

  let refreshPayload = {
    token: oldToken,
  };
  if (userName && orgName && oldToken) {
    axios
      .post("/refresh-jwt-token", refreshPayload)
      .then((res) => {
        if (res && res.status && res.status === 200) {
          axios.defaults.headers.common["Content-Type"] = "application/json";
          axios.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${res.data.token.access}`;
          localStorage.setItem("id_token", res.data.token.access);
          localStorage.setItem("refresh_token", res.data.token.refresh);
          axios.get(`/${orgName}/users/${userName}`).then((resp) => {
            if (resp && resp.data && resp.data.user) {
              localStorage.setItem("organization", orgName);
              localStorage.setItem("first_name", resp.data.first_name);
              localStorage.setItem("last_name", resp.data.last_name);
              localStorage.setItem("user", resp.data.user);
              localStorage.setItem("email", resp.data.user);
              localStorage.setItem(
                "isEnterpriseUser",
                resp.data.user.is_enterprise_user
              );
              localStorage.setItem("isOrgRouted", "false");
              localStorage.setItem("isNotificationShowed", "false");
              localStorage.setItem(
                "isAdmin",
                resp.data.is_admin ? "true" : "false"
              );
              // if(resp.data.is_enterprise_user === true){
              //   localStorage.setItem('enterpriseOrganization', resp.data.user.organization)
              // }
              if (props.match.params) {
                if (props.match.params.dashboardType) {
                  if (props.match.params.deviceId) {
                    props.history.push({
                      pathname: `/app/${props.match.params.dashboardType}/${props.match.params.deviceId}`,
                    });
                  } else {
                    props.history.push({
                      pathname: `/app/${props.match.params.dashboardType}`,
                    });
                  }
                } else {
                  props.history.push({ pathname: "/" });
                }
              }
              dispatch({ type: "LOGIN_SUCCESS" });
            }
          });
        }
      })
      .catch((err) => {
        dispatch({ type: "SIGN_OUT_SUCCESS" });
      });
  }
}

function loginUser(dispatch, login, password, history, setIsLoading, setError) {
  setError(null);
  setIsLoading(true);

  if (!!login && !!password) {
    const user = {
      username: login,
      password: password,
    };

    axios
      .post(`/login`, user)
      .then((res) => {
        if (res.data.user.is_enterprise_user === true) {
          setError("Not a valid org user");
          setIsLoading(false);
          return;
        }
        localStorage.setItem("id_token", res.data.token.access);
        localStorage.setItem("refresh_token", res.data.token.refresh);
        localStorage.setItem("organization", res.data.user.organization);
        localStorage.setItem("first_name", res.data.user.first_name);
        localStorage.setItem("last_name", res.data.user.last_name);
        localStorage.setItem("email", res.data.user.email);
        localStorage.setItem(
          "isEnterpriseUser",
          res.data.user.is_enterprise_user
        );
        localStorage.setItem("isOrgRouted", "false");
        localStorage.setItem(
          "isAdmin",
          res.data.user.is_saladmin ? "true" : "false"
        );
        localStorage.setItem("role", res.data.user.role);
        localStorage.setItem("isReadonly", res.data.user.is_readonly);
        localStorage.setItem("isNotificationShowed", "false");
        setError(null);
        setIsLoading(false);
        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${res.data.token.access}`;
        const profileUrl = `/${res.data.user.organization}/users/${res.data.user.email}/settings`;
        axios.get(profileUrl).then((resp) => {
          if (!resp) return;
          const {
            data: { homepage, ui_config },
          } = resp;
          axios
            .get(`/organizations/${res.data.user.organization}`)
            .then((respZh) => {
              if (!respZh) {
                return;
              } else {
                if (homepage === 1) {
                  history.push("/app/dashboard");
                } else if (homepage === 2) {
                  history.push("/app/alarms");
                } else if (homepage === 3) {
                  history.push("/app/dashboard");
                }
              }
            });
        });
        dispatch({ type: "LOGIN_SUCCESS" });
        window.gtag("event", "login_success", {});
      })
      .catch((err) => {
        setError("Login Failed, Invalid Credentials");
        setIsLoading(false);
      });

    // setTimeout(() => {
    //   localStorage.setItem('id_token', 1)
    //   setError(null)
    //   setIsLoading(false)
    //   dispatch({ type: 'LOGIN_SUCCESS' })

    //   history.push('/app/dashboard')
    // }, 2000);
  } else {
    setError("Login Failed, Invalid Credentials");
    setIsLoading(false);
  }
}

function postAdAuth(dispatch, token, history, enqueueSnackbar) {
  const user = {
    id_token: token,
  };

  let url = config.baseURLApi;
  url = url.replace("/v3", "");

  axios
    .post(`${url}/auth`, user)
    .then((res) => {
      if (res.data.user.is_enterprise_user === true) {
        enqueueSnackbar("Not a valid org user", { variant: "error" });
        history.push("/login");
        return;
      }
      localStorage.setItem("id_token", res.data.token.access);
      localStorage.setItem("refresh_token", res.data.token.refresh);
      localStorage.setItem("organization", res.data.user.organization);
      localStorage.setItem("first_name", res.data.user.first_name);
      localStorage.setItem("last_name", res.data.user.last_name);
      localStorage.setItem("email", res.data.user.email);
      localStorage.setItem(
        "isEnterpriseUser",
        res.data.user.is_enterprise_user
      );
      localStorage.setItem("isOrgRouted", "false");
      localStorage.setItem(
        "isAdmin",
        res.data.user.is_saladmin ? "true" : "false"
      );
      localStorage.setItem("role", res.data.user.role);
      localStorage.setItem("isReadonly", res.data.user.is_readonly);
      localStorage.setItem("isNotificationShowed", "false");
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${res.data.token.access}`;
      const profileUrl = `/${res.data.user.organization}/users/${res.data.user.email}/settings`;
      axios.get(profileUrl).then((resp) => {
        if (!resp) return;
        const {
          data: { homepage, ui_config },
        } = resp;
        axios
          .get(`/organizations/${res.data.user.organization}`)
          .then((respZh) => {
            if (!respZh) {
              return;
            } else {
              if (homepage === 1) {
                history.push("/app/dashboard");
              } else if (homepage === 2) {
                history.push("/app/alarms");
              } else if (homepage === 3) {
                history.push("/app/dashboard");
              }
            }
          });
      });
      dispatch({ type: "LOGIN_SUCCESS" });
      window.gtag("event", "login_success", {});
    })
    .catch((err) => {
      enqueueSnackbar("Login Failed, Invalid Credentials", {
        variant: "error",
      });
      history.push("/login");
    });

  // setTimeout(() => {
  //   localStorage.setItem('id_token', 1)
  //   setError(null)
  //   setIsLoading(false)
  //   dispatch({ type: 'LOGIN_SUCCESS' })

  //   history.push('/app/dashboard')
  // }, 2000);
}

function AdLoginUser(setIsLoading, setError) {
  setError(null);
  setIsLoading(true);

  axios
    .get(`/azure-login`)
    .then((res) => {
      if (res && res.data.url) {
        window.open(res.data.url, "_self");
      }
    })
    .catch((err) => {
      setError("Login Failed, Invalid Credentials");
    });
}

function signOut(dispatch, history) {
  localStorage.removeItem("id_token");
  localStorage.removeItem("role");
  localStorage.removeItem("refresh_token");
  localStorage.removeItem("organization");
  localStorage.removeItem("first_name");
  localStorage.removeItem("last_name");
  localStorage.removeItem("email");
  localStorage.removeItem("user");
  localStorage.removeItem("isEnterpriseUser");
  localStorage.removeItem("isAdmin");
  localStorage.removeItem("enterpriseOrgs");
  localStorage.removeItem("isOrgRouted");
  localStorage.removeItem("enterpriseOrganization");
  localStorage.removeItem("dashboard_config");
  localStorage.removeItem("isNotificationShowed");
  localStorage.removeItem("isReadonly");
  axios.defaults.headers.common["Authorization"] = "";
  dispatch({ type: "SIGN_OUT_SUCCESS" });
  history.push("/login");
}

export {
  UserProvider,
  useUserState,
  useUserDispatch,
  loginUser,
  AdLoginUser,
  postAdAuth,
  authenticateEnterpriseUser,
  signOut,
};
