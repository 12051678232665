import React, { Suspense, useEffect, useState } from "react";
import { Route, Switch, withRouter, Redirect } from "react-router-dom";
import classnames from "classnames";
import { CircularProgress } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";

import useStyles from "./styles";
import Header from "../Header";
import Sidebar from "../Sidebar";
import { useLayoutState } from "../../context/LayoutContext";
import { usePageTracking } from "./../../usePageTracking";
import { getDevicesHealth } from "../../app/devicesSlice";
import { Roles } from "../../util/util";
import Error from "../../pages/error/Error";
import { APIStatus } from "../../app/APIStatus";

const Dashboard = React.lazy(() => import("../../pages/dashboard/Dashboard"));
const RemoteEyeDashboard = React.lazy(() =>
  import("../../pages/remoteEyeDashboard/RemoteEyeDashboard")
);
const Alarms = React.lazy(() => import("../../pages/alarms/Alarms"));
const Triggers = React.lazy(() => import("../../pages/triggers/Triggers"));
const ScheduleReport = React.lazy(() =>
  import("../../pages/scheduleReport/ScheduleReport")
);
const DeviceManagement = React.lazy(() =>
  import("../../pages/device-management/DeviceManagement")
);
const AccountSettings = React.lazy(() =>
  import("../../pages/accountSettings/AccountSettings")
);
const UserManagement = React.lazy(() =>
  import("../../pages/userManagement/UserManagement")
);
const AddDevice = React.lazy(() =>
  import("../../pages/device-management/AddDevice")
);
const EditDevice = React.lazy(() =>
  import("../../pages/device-management/EditDevice")
);
const DeviceSummary = React.lazy(() =>
  import("../../pages/deviceSummary/DeviceSummary")
);
const ChangePassword = React.lazy(() =>
  import("../../pages/changePassword/ChangePassword")
);

function Layout(props) {
  var classes = useStyles();
  var layoutState = useLayoutState();
  const dispatch = useDispatch();
  usePageTracking();
  const [role, setRole] = useState(null);

  const { isLoading: dashboardConfigAPIStatus, dashboardConfig } = useSelector(
    (state) => state.configurationSlice
  );

  const getDashboardTypeToShow = () => {
    if (dashboardConfigAPIStatus !== APIStatus.loading && dashboardConfig) {
      const isRemoteeyeUser =
        dashboardConfig?.meta_data?.custom_config?.is_remoteeye_user?.value ||
        false;
      return isRemoteeyeUser ? <RemoteEyeDashboard /> : <Dashboard />;
    }

    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "5%",
        }}
      >
        <CircularProgress size={24} />
      </div>
    );
  };

  useEffect(() => {
    const organization = localStorage.getItem("organization");
    if (organization) {
      let devicesHealthApi = `/${organization}/devices-health/`;
      dispatch(getDevicesHealth({ url: devicesHealthApi }));
    }
  }, [dispatch]);

  useEffect(() => {
    const roleInfo = localStorage.getItem("role");
    setRole(roleInfo);
  }, []);

  return (
    <div className={classes.root}>
      <>
        <Header history={props.history} />
        <Sidebar />
        <div
          className={classnames(classes.content, {
            [classes.contentShift]: layoutState.isSidebarOpened,
          })}
        >
          <Box minHeight={64} />
          <Suspense
            fallback={
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "5%",
                }}
              >
                <CircularProgress size={24} />
              </div>
            }
          >
            <Switch>
              <Route path="/app/dashboard">{getDashboardTypeToShow()}</Route>
              <Route path="/app/alarms">
                <Alarms />
              </Route>
              {(role === Roles.ADMIN || role === Roles.ENGINEER) && (
                <Route path="/app/triggers">
                  <Triggers />
                </Route>
              )}
              <Route path="/app/deviceManagement/manageDevices">
                <DeviceManagement />
              </Route>
              <Route path="/app/deviceManagement/addDevice">
                <AddDevice />
              </Route>
              <Route path="/app/deviceManagement/editDevice/:deviceId">
                <EditDevice />
              </Route>
              <Route path="/app/deviceSummary/:deviceId">
                <DeviceSummary />
              </Route>
              <Route path="/app/changePassword">
                <ChangePassword />
              </Route>
              <Route exact path="/app/accountSettings">
                <Redirect to="/app/accountSettings/0" />
              </Route>
              <Route path="/app/accountSettings/:activeTab">
                <AccountSettings />
              </Route>
              <Route path="/app/userManagement">
                <UserManagement />
              </Route>
              <Route path="/app/scheduleReports">
                <ScheduleReport />
              </Route>
              <Route path="*">
                <Error />
              </Route>
            </Switch>
          </Suspense>
        </div>
      </>
    </div>
  );
}

export default withRouter(Layout);
