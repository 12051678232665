import { useEffect } from "react";
import { postAdAuth, useUserDispatch } from "../../context/UserContext";
import { useLocation } from "react-router-dom";
import { CircularProgress, Box } from "@mui/material";
import { useSnackbar } from "notistack";

const AdLogin = (props) => {
  const userDispatch = useUserDispatch();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.pathname.substring(1));
    const token = queryParams.get("id_token");

    if (token) {
      postAdAuth(userDispatch, token, props.history, enqueueSnackbar);
    }
  }, [enqueueSnackbar, location, props.history, userDispatch]);

  return (
    <Box
      sx={{
        display: "flex",
        height: "100vh",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CircularProgress />
    </Box>
  );
};

export default AdLogin;
