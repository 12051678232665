import {
  Box,
  Button,
  Card,
  CircularProgress,
  CssBaseline,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";

import {
  loginUser,
  AdLoginUser,
  useUserDispatch,
} from "../../context/UserContext";
import { resetUserPassword } from "../../app/resetPasswordSlice";
import { usePageTracking } from "../../usePageTracking";
import { getErrorFormattedMessage } from "../../util/util";

import useStyles from "./newstyles";
import smalllogo from "./SpaceAge-Icon-Grayscale.png";

var NewLogin = (props) => {
  var classes = useStyles();
  var userDispatch = useUserDispatch();
  var [isLoading, setIsLoading] = useState(false);
  var [error, setError] = useState(null);
  var [reseterror] = useState(null);
  var [resetsuccess] = useState(null);
  var [, setloginSuccess] = useState(null);
  const [forgotPasswordStatus, setForgotPasswordStatus] = useState(false);
  var [loginValue, setLoginValue] = useState("");
  var [passwordValue, setPasswordValue] = useState("");
  const [resetPasswordEmail, setResetPasswordEmail] = useState("");
  const [resetPasswordOrganization, setResetPasswordOrganization] =
    useState("");
  const [resetPasswordResponse, setResetPasswordResponse] = useState("");
  const [messageOpen, setMessageOpen] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  var [errorMsg] = useState("");
  const [vertical] = React.useState("top");
  const [horizontal] = React.useState("right");
  const dispatch = useDispatch();

  usePageTracking();

  useEffect(() => {
    document.title = "hydroEye - Login";
  }, []);

  const resetPasswordStatus = useSelector(
    (state) => state.resetPasswordSlice.resetPasswordStatus
  );

  const resetPasswordError = useSelector(
    (state) => state.resetPasswordSlice.resetPasswordError
  );

  useEffect(() => {
    if (resetPasswordStatus && isLoading) {
      setResetPasswordResponse(resetPasswordStatus);
      setErrorOpen(false);
      setMessageOpen(true);
      setForgotPasswordStatus(false);
      setIsLoading(false);
    }
    if (resetPasswordError && isLoading) {
      const errorMessage = getErrorFormattedMessage(resetPasswordError);
      setResetPasswordResponse(errorMessage);
      setMessageOpen(false);
      setErrorOpen(true);
      setIsLoading(false);
    }
    // eslint-disable-next-line
  }, [resetPasswordStatus, resetPasswordError]);

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
  };

  return (
    <Box component="main" className={classes.main}>
      <CssBaseline />
      <div className={classes.siteLogo}>
        <img src={smalllogo} alt="logo" className={classes.siteLogoIcon} />
      </div>
      <Box
        sx={{
          marginTop: 0,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Card className={classes.loginCard}>
          <Typography
            component="h1"
            variant="h5"
            style={{ color: "#23679a" }}
            className={classes.title}
          >
            hydroEye AI
          </Typography>
          <Typography
            component="h1"
            variant="subtitle1"
            style={{ marginTop: "5px", color: "#616161" }}
            className={classes.title}
          >
            Smart Platform for your Water Network!
          </Typography>
          {!forgotPasswordStatus && (
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 1 }}
            >
              <TextField
                variant="standard"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                value={loginValue}
                onChange={(e) => setLoginValue(e.target.value)}
              />
              <TextField
                variant="standard"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                value={passwordValue}
                onChange={(e) => setPasswordValue(e.target.value)}
                onKeyUp={(event) => {
                  if (event.key === "Enter") {
                    loginUser(
                      userDispatch,
                      loginValue,
                      passwordValue,
                      props.history,
                      setIsLoading,
                      setError,
                      setloginSuccess
                    );
                  }
                }}
              />
              {isLoading ? (
                <div className={classes.loader}>
                  <CircularProgress size={26} />
                </div>
              ) : (
                <>
                  <Button
                    onClick={() => {
                      if (loginValue.length > 0 || passwordValue.length > 0) {
                        loginUser(
                          userDispatch,
                          loginValue,
                          passwordValue,
                          props.history,
                          setIsLoading,
                          setError
                        );
                      }
                    }}
                    fullWidth
                    variant="contained"
                    color="primary"
                    size="large"
                    className={classes.signInBtn}
                  >
                    Sign In
                  </Button>
                  <Button
                    onClick={() => {
                      AdLoginUser(setIsLoading, setError);
                    }}
                    fullWidth
                    variant="contained"
                    color="secondary"
                    size="large"
                    className={classes.signInBtn}
                  >
                    Authenticate via AD
                  </Button>
                </>
              )}

              <Grid container>
                <Grid
                  style={{
                    padding: 10,
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                  item
                  xs
                >
                  <div>
                    <p
                      style={{
                        marginTop: 10,
                        color: "#2d4d76",
                        fontSize: 14,
                        fontFamily: "'Karla', sans-serif",
                        textAlign: "right",
                        cursor: "pointer",
                      }}
                      // onClick={() => forgotPasswordHandler()}
                      onClick={() => setForgotPasswordStatus(true)}
                    >
                      Forgot Password?
                    </p>
                  </div>
                </Grid>
              </Grid>
              <div className={classes.copyrightDiv}>
                {" "}
                &copy; Powered by SpaceAge Labs{" "}
              </div>
            </Box>
          )}
          {forgotPasswordStatus && (
            <Box>
              <p style={{ textAlign: "center" }}>
                Enter your E-mail address we will send you a link to reset your
                password
              </p>
              <TextField
                id="resetPasswordEmail"
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                value={resetPasswordEmail}
                onChange={(e) => setResetPasswordEmail(e.target.value)}
                margin="normal"
                placeholder="Email Address"
                type="email"
                fullWidth
              />
              <TextField
                id="resetPasswordOrganization"
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                value={resetPasswordOrganization}
                onChange={(e) => setResetPasswordOrganization(e.target.value)}
                margin="normal"
                placeholder="Organization Name"
                type="text"
                fullWidth
              />
              <div
                className={classes.formButtons}
                style={{ display: "flex", justifyContent: "center" }}
              >
                {isLoading ? (
                  <CircularProgress size={26} className={classes.loginLoader} />
                ) : (
                  <Button
                    disabled={
                      resetPasswordEmail.length === 0 ||
                      resetPasswordOrganization.length === 0
                    }
                    onClick={() => {
                      dispatch(
                        resetUserPassword({
                          email: resetPasswordEmail,
                          organization: resetPasswordOrganization,
                        })
                      );
                      setIsLoading(true);
                    }}
                    variant="contained"
                    color="primary"
                    size="large"
                  >
                    Send
                  </Button>
                )}
              </div>
              <div>
                <p
                  style={{
                    margin: 0,
                    marginTop: "10px",
                    textAlign: "center",
                    cursor: "pointer",
                    color: "#23679a",
                    fontFamily: "'Karla', sans-serif",
                  }}
                  onClick={() => setForgotPasswordStatus(false)}
                >
                  Back to Login
                </p>
              </div>
            </Box>
          )}
        </Card>
      </Box>

      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={error !== null}
        autoHideDuration={3000}
      >
        <Alert severity="error" elevation={6} variant="filled">
          {error}
        </Alert>
      </Snackbar>

      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={resetsuccess}
        autoHideDuration={3000}
      >
        <Alert severity="success" elevation={6} variant="filled">
          Reset Password Email Sent Successfully
        </Alert>
      </Snackbar>

      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={reseterror}
        autoHideDuration={3000}
      >
        <Alert severity="error" elevation={6} variant="filled">
          {errorMsg}
        </Alert>
      </Snackbar>

      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={messageOpen}
        autoHideDuration={3000}
        onClose={() => setMessageOpen(false)}
      >
        <Alert severity="success" elevation={6} variant="filled">
          {resetPasswordResponse}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={errorOpen}
        autoHideDuration={3000}
        onClose={() => setErrorOpen(false)}
      >
        <Alert severity="error" elevation={6} variant="filled">
          {resetPasswordResponse}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default withRouter(NewLogin);
