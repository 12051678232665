import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { APIStatus } from "./APIStatus";

const initialState = {
  isLoading: APIStatus.loading,
  assetTypeBB: {
    accessShaftNodes: null,
    shaftOutline: null,
    trunkMh: null,
    trunkMhOutline: null,
    workSiteAssets: null,
  },
  assetTypeBBError: null,
  categoryBBError: null,
  categoryBB: {
    pipelineSetbackDtss: null,
    pipelineSetbackTrunkSewers: null,
    pipelineSetbackWaterPipes: null,
    pipelineDtss: null,
    pipelineTrunkSewers: null,
    pipelineWaterPipes: null,
    loading: null,
  },
  bbLocations: null,
  bbLocationsError: null,
};

export const getAssetTypeBB = createAsyncThunk(
  "getAssetTypeBB",
  async (payload) => {
    let response = await axios.get(payload.url);
    return response;
  }
);

export const getCategoryLayerBB = createAsyncThunk(
  "getCategoryLayerBB",
  async (payload) => {
    let response = await axios.get(payload.url);
    response.fromPage = payload.fromPage;
    return response;
  }
);

export const getBBLocations = createAsyncThunk(
  "getBBLocations",
  async (payload) => {
    const response = await axios.get(payload.url);

    return response;
  }
);

const assetSlice = createSlice({
  name: "assets",
  initialState,
  reducers: {
    resetAssetTypeBB(state, action) {
      state.assetAttachHistory = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAssetTypeBB.pending, (state, action) => {
      state.isLoading = APIStatus.loading;
    });
    builder.addCase(getAssetTypeBB.fulfilled, (state, action) => {
      state.isLoading = APIStatus.loaded;
      let value = action.payload.config.url;

      if (value !== null) {
        let trimdat = value.split("asset_type=");
        let assetType = trimdat.pop();
        switch (assetType) {
          case "geofence_pub_access_shaft_nodes":
            state.assetTypeBB.accessShaftNodes = action.payload.data;
            break;
          case "geofence_pub_shaft_outlines":
            state.assetTypeBB.shaftOutline = action.payload.data;
            break;
          case "geofence_pub_trunk_mh":
            state.assetTypeBB.trunkMh = action.payload.data;
            break;
          case "geofence_pub_trunk_mh_outlines":
            state.assetTypeBB.trunkMhOutline = action.payload.data;
            break;
          case "work_site":
            state.assetTypeBB.workSiteAssets = action.payload.data;
            break;
        }
      }
    });
    builder.addCase(getAssetTypeBB.rejected, (state, action) => {
      state.isLoading = APIStatus.failed;
      state.assetTypeBBError = action.payload;
    });
    builder.addCase(getCategoryLayerBB.pending, (state, action) => {
      if (action.meta.arg.fromPage === "dashboard") {
        state.categoryBB.loading = APIStatus.loading;
      }
    });
    builder.addCase(getCategoryLayerBB.fulfilled, (state, action) => {
      let value = action.payload.config.url;
      if (value !== null) {
        let trimdat = value.split("&location_type=");
        let categoryName = trimdat.pop();
        switch (categoryName) {
          case "pipeline-setback-dtss":
            state.categoryBB.pipelineSetbackDtss = action.payload.data;
            break;

          case "pipeline-setback-trunk-sewers":
            state.categoryBB.pipelineSetbackTrunkSewers = action.payload.data;
            break;

          case "pipeline-setback-water-pipes":
            state.categoryBB.pipelineSetbackWaterPipes = action.payload.data;
            break;

          case "pipeline-dtss":
            state.categoryBB.pipelineDtss = action.payload.data;
            break;

          case "pipeline-trunk-sewers":
            state.categoryBB.pipelineTrunkSewers = action.payload.data;
            break;

          case "pipeline-water-pipes":
            state.categoryBB.pipelineWaterPipes = action.payload.data;
            break;
        }
        if (action.payload.fromPage === "dashboard") {
          state.categoryBB.loading = APIStatus.loaded;
        }
      }
      state.isLoading = APIStatus.loaded;
    });
    builder.addCase(getCategoryLayerBB.rejected, (state, action) => {
      state.categoryBBError = action.payload;
      state.categoryBB.loading = APIStatus.failed;
      state.isLoading = APIStatus.failed;
    });
    builder.addCase(getBBLocations.fulfilled, (state, action) => {
      state.bbLocations = action.payload.data;
      state.isLoading = APIStatus.loaded;
    });
    builder.addCase(getBBLocations.rejected, (state, action) => {
      state.bbLocationsError = action.payload;
      state.isLoading = APIStatus.failed;
    });
  },
});

export const { resetAssetTypeBB } = assetSlice.actions;

export default assetSlice.reducer;
