import { Button, CircularProgress, Grid, Tab, Tabs, TextField } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/lab/Alert';
import React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { useHistory, useLocation } from 'react-router-dom';

import { resetPasswordEmail } from '../../app/resetPasswordSlice';
import icon from '../verifyEmail/icon.png';
import { getErrorFormattedMessage } from "../../util/util";

import useStyles from './styles';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const ResetPassword = () => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [activeTabId, setActiveTabId] = useState(0);
  const [passwordOne, setPasswordOne] = useState('');
  const [passwordTwo, setPasswordTwo] = useState('');
  const [displayMessage, setDisplayMessage] = useState('');
  const [isError, setIsError] = useState(false);
  const [isMessage, setIsMessage] = useState(false);
  useEffect(() => {
    document.title = 'hydroEye - Reset Password';
  }, []);

  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const resetPasswordEmailError = useSelector(
    (state) => state.resetPasswordSlice.resetPasswordEmailError
  );

  const resetPasswordEmailSuccess = useSelector(
    (state) => state.resetPasswordSlice.resetPasswordEmailStatus
  );

  useEffect(() => {
    if (resetPasswordEmailError && isLoading) {
      setIsMessage(false);
      setIsError(true);
      const errorMessage = getErrorFormattedMessage(resetPasswordEmailError);
      setDisplayMessage(errorMessage);
      setIsLoading(false);
    }
    if (resetPasswordEmailSuccess && isLoading) {
      setIsError(false);
      setIsMessage(true);
      setDisplayMessage(`${resetPasswordEmailSuccess} Navigating to Login Screen`);
      setIsLoading(false);
      setTimeout(() => {
        history.push({
          pathname: '/login',
        });
      }, 3000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetPasswordEmailSuccess, resetPasswordEmailError]);

  let locationInfo = location.pathname.split('/');
  let UID = locationInfo[3];
  let token = locationInfo[5];

  const vertical = 'top';
  const horizontal = 'right';

  const handlePasswordReset = () => {
    setIsLoading(true);
    dispatch(
      resetPasswordEmail({
        new_password1: passwordOne,
        new_password2: passwordTwo,
        uid: UID,
        token,
      })
    );
  };
  return (
    <div
      style={{
        display: 'flex',
        height: '100vh',
        alignItems: 'center',

        // alignContent:'center',
        justifyContent: 'center',
      }}
    >
      <div>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={isError}
          autoHideDuration={3000}
        >
          <Alert severity="error">{displayMessage}</Alert>
        </Snackbar>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={isMessage}
          autoHideDuration={3000}
        >
          <Alert severity="success">{displayMessage}</Alert>
        </Snackbar>
      </div>

      <Grid container className={classes.container}>
        <Grid className={classes.logotypeContainer}>
          {/* <img src={logo} alt="logo" className={classes.smalllogotypeImage} /> */}
          <img src={icon} alt="logo" className={classes.logoImage} />
        </Grid>
        <Grid className={classes.formContainer}>
          <Tabs
            value={activeTabId}
            onChange={(e, id) => setActiveTabId(id)}
            indicatorColor="primary"
            textColor="primary"
            centered
            style={{ marginBottom: '10%' }}
          >
            <Tab label="Reset Your Password" classes={{ root: classes.tab }} />
          </Tabs>
          <TextField
            id="passwordOne"
            InputProps={{
              classes: {
                underline: classes.textFieldUnderline,
                input: classes.textField,
              },
            }}
            value={passwordOne}
            onChange={(e) => setPasswordOne(e.target.value)}
            margin="normal"
            placeholder="New Password"
            type="password"
            helperText="Please Enter a Password with atleast 8 Characters"
          />
          <TextField
            id="passwordTwo"
            InputProps={{
              classes: {
                underline: classes.textFieldUnderline,
                input: classes.textField,
              },
            }}
            value={passwordTwo}
            onChange={(e) => setPasswordTwo(e.target.value)}
            margin="normal"
            placeholder="Re-Enter Password"
            type="password"
            helperText="Please Enter a Password with atleast 8 Characters"
          />
          <div className={classes.formButtons}>
            {isLoading ? (
              <CircularProgress size={26} className={classes.loginLoader} />
            ) : (
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <Button
                  disabled={
                    passwordOne.length === 0 || passwordTwo.length === 0
                  }
                  onClick={() => {
                    handlePasswordReset();
                  }}
                  variant="contained"
                  color="primary"
                  size="large"
                >
                  Submit
                </Button>
                <Button
                  onClick={() => {
                    history.push({
                      pathname: '/login',
                    });
                  }}
                  variant="contained"
                  color="primary"
                  size="large"
                  style={{ marginLeft: '50px' }}
                >
                  Cancel
                </Button>
              </div>
            )}
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default withRouter(ResetPassword);
